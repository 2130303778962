<template>
    <div class="py-20 flex justify-around">
        <btn-component @click="login">Log in</btn-component>
        <btn-component @click="signup">Sign up</btn-component>
    </div>
</template>

<script>
import BtnComponent from "./BtnComponent";

export default {
    name: "RedirectComponent",
    components: {BtnComponent},
    methods: {
        login: () => window.location.replace(`https://app.kotpartners.com/login`),
        signup: () => window.location.replace(`https://app.kotpartners.com/register`)
    }
}
</script>
