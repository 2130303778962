<template>
    <div class="flex justify-center flex-wrap">
        <div class="w-[592px] p-2 lg:order-2">
            <div class="rounded-3xl m-2 bg-687ED678">
                <div class="bg-142050 min-h-28 rounded-t-3xl py-2 text-center">
                    <div class="text-[34px] tracking-[0.22em]">King Of Traffic</div>
                    <div class="text-gray-400 text-xl">Gambling ПП, Которую Ты Искал</div>
                </div>
                <FormComponent/>
            </div>
        </div>
        <div class="w-[296px] p-2 lg:order-1">
            <CardComponent v-for="item in litems" :item="item" :key="item.title"/>
        </div>
        <div class="w-[296px] p-2 lg:order-3">
            <CardComponent v-for="item in ritems" :item="item" :key="item.title"/>
        </div>
    </div>
</template>

<script>
import CardComponent from "./CardComponent.vue";
import FormComponent from "./FormComponent.vue";

export default {
    name: "MainComponent",
    components: {CardComponent, FormComponent},
    data() {
        return {
            litems: [
                {title: "Быстрые бампы", image: "/icons/bumps.svg"},
                {title: "Лучший Саппорт во Всей Сфере", image: "/icons/support.svg"},
                {title: "Большой выбор офферов", image: "/icons/offers.svg"},
            ],
            ritems: [
                {title: "Бесплатные прилы", image: "/icons/apps.svg"},
                {title: "Вывод от 50$", image: "/icons/withdraws.svg"},
                {title: "Минимальный холд", image: "/icons/hold.svg"},
            ],
        };
    },
};
</script>
<style>
.bg-687ED678 {
    background-color: #3f4c84;
}

.bg-142050 {
    background-color: #142050;
}
</style>
